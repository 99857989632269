import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import '../assets/styles/base/standard-page.scss';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AnouncementBar from '../components/home/announcement-bar';

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      about {
        seo {
          canonical
          description
          id
          og {
            description
            id
            image
            title
            type
            url
          }
          title
        }
      }
      ourMission: file(relativePath: { eq: "about/about-our-mission.png" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ourPromise: file(relativePath: { eq: "about/about-our-promise.png" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ourValues: file(relativePath: { eq: "about/about-our-values.png" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ourRepairers: file(relativePath: { eq: "about/about-our-repairers.png" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const { seo } = data.about;
  return (
    <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO
        title={seo.title}
        description={seo.title}
        canonical={seo.canonical}
        stucturedData=""
        ogTitle={seo.og.title}
        ogDescription={seo.og.description}
        ogImage={seo.og.image}
        ogType={seo.og.type}
        ogUrl={seo.og.url}
      />
      <AnouncementBar />
      <div className="hero-banner">
        <div className="container">
          <h6>About Us</h6>
          <h1>DingGo's Mission</h1>
        </div>
      </div>
      <div className="main-wrapper">
        <div className="py-7">
          <div className="container">
            <div className="row flex-row-reverse justify-content-around align-items-center">
              <div className="col-4">
                <Img fluid={data.ourMission.childImageSharp.fluid} />
              </div>
              <div className="col-6">
                <h2>Our mission at DingGo is to ensure everyone can afford the time and money to have their car repaired back to the condition they bought it in.</h2>
                <p>We're a young company combining the power of the internet with the craft
                and quality of the car repair industry. This combination lets us replace
                outdated practices (like driving around for quotes), with a completely
                digital experience, from submitting your damage, to getting quotes to compare,
                            and then choosing to the right repairer for you.</p>
              </div>
            </div>
          </div>
        </div>
        <section className="py-7 bg-light">
          <div className="container">
            <div className="row mb-7 justify-content-around align-items-center">
              <div className="col-4">
                <Img fluid={data.ourPromise.childImageSharp.fluid} />
              </div>
              <div className="col-6">
                <h2>Our promise to every customer is to provide the easiest way to repair your car with maximum care and minimum cost.</h2>

                <p>We love cars, we are obsessed about them. They are beautiful feats of
                human engineering. That engineering sophistication and complexity also
                means they are expensive to fix when they are damaged and it’s never an
                easy process. Luckily the only thing we care about as much as cars is humans
                (and dogs too ). We will do everything in our power to provide maximum
                care to everyone human we can help with their cars and ensure we do it
                            at minimum cost.</p>
              </div>
            </div>
            <div className="row mb-7 flex-row-reverse justify-content-around align-items-center">
              <div className="col-4">
                <Img fluid={data.ourValues.childImageSharp.fluid} />
              </div>
              <div className="col-6">
                <h2>At DingGo we are a bunch of good humans who like to help other great humans with their cars. We are a values based company who work and live by our values.</h2>

                <p>The values we live by are to provide Care, Value and Ease to every human
                            interaction we have (be it digital or physical).</p>
                <ul>
                  <li>We care about people and their cars. We will always help you find the
                                right solution.</li>
                  <li>We strive to provide easy, stress-free car repair. We are ruthlessly simple
                                and practical in helping find the right solution.</li>
                  <li>We want to find the best solution at the best possible value.</li>
                </ul>
                <p>We don’t believe in compromising on the quality in order to find the lowest
                            price for what your car needs.</p>
              </div>
            </div>
            <div className="row mb-7 justify-content-around align-items-center">
              <div className="col-4">
                <Img fluid={data.ourRepairers.childImageSharp.fluid} />
              </div>
              <div className="col-6">
                <h2>We love all humans. Especially our repairers. They are our favourite humans.</h2>

                <p>At DingGo we are proud to work with every repairer in our network. We
                only work with the best, independent repairers who are highly respected
                by their customers and communities. Every repairer is verified by DingGo
                before joining the network and is reviewed on DingGo by every human they
                            help through us.</p>
                <p>Our values extend to this group of legends too. We are constantly striving
                to make our repairers lives easier (quoting, booking and winning jobs),
                we care about them, listening to their needs and working with them to make
                our product and experience better for them, and lastly we help provide
                value for their business by making it as easy as possible for them to win
                            more work when they need it.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AboutUs;