import React from 'react';
import { Link } from 'gatsby';
import '../../assets/styles/components/announcement-bar.scss';

const AnnouncementBar = () => (
  <div className="announcement-bar-wrapper">
    <div className="container">
      <Link to="/contactless">
        Contactless repair available. We are committed to supporting your health during coronavirus (COVID-19)
        <span>Learn more</span>
      </Link>
    </div>
  </div>
);

export default AnnouncementBar;
